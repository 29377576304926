<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.02772 30.1666H5.13883C4.76311 30.1666 4.40278 30.0174 4.1371 29.7517C3.87142 29.486 3.72217 29.1257 3.72217 28.75V20.25C3.72217 19.8743 3.87142 19.5139 4.1371 19.2482C4.40278 18.9826 4.76311 18.8333 5.13883 18.8333H7.02772C7.40345 18.8333 7.76378 18.9826 8.02946 19.2482C8.29513 19.5139 8.44439 19.8743 8.44439 20.25V28.75C8.44439 29.1257 8.29513 29.486 8.02946 29.7517C7.76378 30.0174 7.40345 30.1666 7.02772 30.1666ZM20.2499 30.1666H18.3611C17.9853 30.1666 17.625 30.0174 17.3593 29.7517C17.0936 29.486 16.9444 29.1257 16.9444 28.75V14.5833C16.9444 14.2076 17.0936 13.8473 17.3593 13.5816C17.625 13.3159 17.9853 13.1666 18.3611 13.1666H20.2499C20.6257 13.1666 20.986 13.3159 21.2517 13.5816C21.5174 13.8473 21.6666 14.2076 21.6666 14.5833V28.75C21.6666 29.1257 21.5174 29.486 21.2517 29.7517C20.986 30.0174 20.6257 30.1666 20.2499 30.1666ZM26.8611 30.1666H24.9722C24.5964 30.1666 24.2361 30.0174 23.9704 29.7517C23.7048 29.486 23.5555 29.1257 23.5555 28.75V7.9722C23.5555 7.59648 23.7048 7.23614 23.9704 6.97047C24.2361 6.70479 24.5964 6.55554 24.9722 6.55554H26.8611C27.2368 6.55554 27.5971 6.70479 27.8628 6.97047C28.1285 7.23614 28.2777 7.59648 28.2777 7.9722V28.75C28.2777 29.1257 28.1285 29.486 27.8628 29.7517C27.5971 30.0174 27.2368 30.1666 26.8611 30.1666ZM13.6388 30.1666H11.7499C11.3742 30.1666 11.0139 30.0174 10.7482 29.7517C10.4825 29.486 10.3333 29.1257 10.3333 28.75V3.24998C10.3333 2.87426 10.4825 2.51392 10.7482 2.24825C11.0139 1.98257 11.3742 1.83331 11.7499 1.83331H13.6388C14.0146 1.83331 14.3749 1.98257 14.6406 2.24825C14.9062 2.51392 15.0555 2.87426 15.0555 3.24998V28.75C15.0555 29.1257 14.9062 29.486 14.6406 29.7517C14.3749 30.0174 14.0146 30.1666 13.6388 30.1666Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '32'
    },
    height: {
      type: String,
      default: '32'
    }
  }
}
</script>
